button {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

button:hover {
    cursor: pointer;
}

input,
textarea,
select {
    appearance: none;
    border: 0;
    border-radius: 0;
    margin: 0;
}

header a, header a:active, header a:focus, header a:hover {
    text-decoration: none;
}

header a {
    color: inherit;
}

input[type='search'] {
   box-sizing: border-box;
}
