html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    color: #3f3f3f;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.75;
}
